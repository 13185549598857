<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
            },
            prefill: {
                type: [String, Number],
                default: null,
            },
            size: {
                type: Number,
                default: 3,
            },
            sync: {
                type: String,
            },
            uploadTo: {
                type: String,
            },
        },

        data() {
            return {
                value: [],
            }
        },

        watch: {
            value(value) {
                let fileData = new FormData()
                fileData.append('file', value, value.name)
                this.$root.axios().post(this.uploadTo, fileData).then(response => {
                    if ('data' in response.data && 'File' in response.data.data) {
                        this.$emit('sync', value)
                        this.$children[0].updateValue(value)
                    } else {
                        console.warn('Somehow an error...')
                    }
                })
            },
        },

        methods: {
            appendTo(content) {
                if (this.name) {
                    if (this.localize) {
                        let localeKey = this.localize + 'I18ns'

                        if (!(localeKey in content)) {
                            content[localeKey] = [{}]
                            if (this.localeId > 0) {
                                content[localeKey][0]['Id'] = this.localeId
                            } else {
                                content[localeKey][0]['Locale'] = this.$root.settings('locale')
                            }
                        }

                        content[localeKey][0][this.name] = this.$children[0].value
                    } else {
                        content[this.name] = this.$children[0].value
                    }
                } else {
                    content['__' + this.label] = this.$children[0].value
                }
                return content
            },
        },
    }
</script>

<template>
    <bg-form-wrapper :label="label" :name="name" :size="size" :prefill="prefill" v-slot="settings">
        <b-upload v-model="value" style="width: 100%">
            <a class="button is-primary" style="width: 100%">
                <b-icon icon="upload"></b-icon>
                <span>{{ $t('Upload file immediately') }}</span>
            </a>
        </b-upload>
    </bg-form-wrapper>
</template>
