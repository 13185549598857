<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
            },
            prefill: {
                type: [String, Number],
                default: null,
            },
            localize: {
                type: String,
                default: null,
            },
            localeId: {
                type: Number,
                default: -1,
            },
            size: {
                type: Number,
                default: 3,
            },
            sync: {
                type: String,
            },
            textarea: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            password: {
                type: Boolean,
                default: false,
            },
            displayAsTable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                value: '',
            }
        },

        watch: {
            value(value) {
                this.$emit('sync', value)
                this.$children[0].updateValue(value)
            },
            prefill(value) {
                this.value = value
            },
        },

        mounted() {
            this.value = this.prefill
        },

        methods: {
            appendTo(content) {
                if (this.name) {
                    if (this.localize) {
                        let localeKey = this.localize + 'I18ns'

                        if (!(localeKey in content)) {
                            content[localeKey] = [{}]
                            if (this.localeId > 0) {
                                content[localeKey][0]['Id'] = this.localeId
                            }
                            content[localeKey][0]['Locale'] = this.$root.settings('locale')
                        }

                        content[localeKey][0][this.name] = this.$children[0].value
                    } else {
                        content[this.name] = this.$children[0].value
                    }
                } else {
                    content['__' + this.label] = this.$children[0].value
                }
                return content
            },
        },
    }
</script>

<template>
    <bg-form-wrapper :label="label" :name="name" :size="size" :prefill="prefill" :displayAsTable="displayAsTable"
                     v-slot="settings">
        <b-input v-model="value" :type="(textarea ? 'textarea' : (password ? 'password' : ''))"
                 :disabled="settings.get.disabled" :readonly="readonly"></b-input>
    </bg-form-wrapper>
</template>
