<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
            },
            translation: {
                type: String,
            },
            prefill: {
                type: [String, Number],
                default: null,
            },
            placeholder: {
                type: String,
            },
            options: {
                type: Array,
                default: () => [],
            },
            optionId: {
                type: Function,
            },
            optionLabel: {
                type: Function,
            },
            optionGroup: {
                type: Function,
            },
            optionGroupLabel: {
                type: Function,
            },
            skip: {
                type: Function,
            },
            size: {
                type: Number,
                default: 3,
            },
            addColumnClass: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                value: '',
                optionsIntern: this.options,
            }
        },

        watch: {
            value(value) {
                this.$emit('sync', value)
                this.$children[0].updateValue(value)
            },
            options(value) {
                this.optionsIntern = value
            },
            prefill(value) {
                this.value = value
            },
        },

        mounted() {
            this.value = this.prefill
        },

        methods: {
            appendTo(content) {
                if (this.value !== null) {
                    content[this.name] = this.value
                    content['__' + this.name] = this.optionsIntern.find(option => {
                        return (option.Id == this.value)
                    })
                }
                return content
            },
        },
    }
</script>

<template>
    <bg-form-wrapper :label="label" :name="name" :size="size" :prefill="prefill" :addColumnClass="addColumnClass" v-slot="settings">
        <b-select :placeholder="placeholder" v-model="value" :disabled="settings.get.disabled || disabled">
            <template v-for="(option, index) in optionsIntern">

                <template v-if="typeof optionGroup !== 'undefined' && typeof optionGroupLabel !== 'undefined'">
                    <optgroup :key="index" :label="optionGroupLabel(option)">
                        <template v-for="(value, key) in optionGroup(option)">
                            <option
                                    v-if="typeof skip !== 'function' || !skip(value, key)"
                                    :key="key"
                                    :value="typeof optionId !== 'undefined' ? optionId(value, key) : key">
                                {{ typeof optionLabel !== 'undefined' ? optionLabel(value, key) : value }}
                            </option>
                        </template>
                    </optgroup>
                </template>

                <template v-else>
                    <option
                            v-if="typeof skip !== 'function' || !skip(option, index)"
                            :key="index"
                            :value="typeof optionId !== 'undefined' ? optionId(option, index) : index">
                        {{ typeof optionLabel !== 'undefined' ? optionLabel(option, index) : option }}
                    </option>
                </template>

            </template>
        </b-select>
    </bg-form-wrapper>
</template>
