<script>
    import Card from '@/components/Card'

    export default {
        components: {
            Card,
        },

        mounted() {
            this.$root.dataLanguages;
            this.$root.restrictCountries('*')
            this.$store.commit('mainPage', {
                current: this.$t('Folders'),
                stack: [{
                    'path': '/folders',
                    'title': this.$t('Folders'),
                }]
            })
        }
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table
                        :label="$t('Folders')"
                        view="details?for=Folder&amp;id=${Id}"
                        :remove="{ id: 'Id', translation: 'FolderI18ns', name: 'Name', desc: $t('Folder') }"
                        create="create?for=Folder"
                        path="folder"
                        path-append="?order[column]=CollectionTypeId"
                        object="Folder"
                        group-by="CollectionTypeId"
                        group-data="CollectionType">

                    <template v-slot="data">
                        <bg-column :label="$t('Name')" sort="Name">
                            {{ $root.translation(data.row.FolderI18ns).Name }}
                        </bg-column>
                        <bg-column :label="$t('Shortcut')" sort="Shortcut">{{ data.row.Shortcut }}</bg-column>
                        <bg-column :label="$t('Required count')" sort="RequiredCount">
                            {{ data.row.RequiredCount }}
                        </bg-column>
                    </template>

                    <template v-slot:group="data">
                        <bg-table-group span="3" :label="$root.translation(data.row.CollectionTypeI18ns, null, $root.$i18n.locale).Name ? $root.translation(data.row.CollectionTypeI18ns, null, $root.$i18n.locale).Name : $root.translation(data.row.CollectionTypeI18ns, null, 'de_AT').Name"/>
                    </template>
                </bg-table>
            </card>
        </div>
    </section>
</template>
