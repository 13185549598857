<script>
import BgStateModal from '@/components/BgStateModal'

export default {
    components: {
        BgStateModal: BgStateModal,
    },

    props: {
        path: {
            type: String,
            required: true,
        },
        pathAppend: {
            type: String,
        },
        object: {
            type: String,
            default: undefined,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        siteCount: {
            type: Boolean,
            default: true,
        },
        noChanges: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
            default: '',
        },
        remove: {
            type: Object,
            default () {
                return {}
            },
        },
        create: {
            type: String,
            default: '',
        },
        groupBy: {
            type: String,
            default: undefined,
        },
        groupData: {
            type: String,
            default: undefined,
        },
        filter: {
            type: Object,
            default () {
                return {}
            },
        },
        label: {
            type: String,
            default: '',
        },
        stateView: {
            type: Boolean,
            default: false,
        },
        detailView: {
            type: String,
            default: '',
        },
        mainView: {
            type: Boolean,
            default: false,
        },
        selectedSystemParam: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            query: {
                pagination: {
                    total: 1,
                    current: 1,
                    perPage: 10,
                    currentInput: 1,
                },
                sorting: {
                    field: null,
                    direction: null,
                },
            },
            items: [],
            openedDetail: [],
            itemsLoading: false,
            liveFilter: this.filter,
            updated: false,
            columnWrapper: {},
            editState: false,
            editStateObject: '',
            settings: {
                visibilities: {},
                functions: {},
            },
            selectedGroup: [],
            selectedProduct: [],
            selectedSystem: 0,
            groupOptions: [],
            productOptions: [],
            systemOptions: [],
        }
    },

    watch: {
        path: function (after, before) {
            if (after !== null && before !== null) {
                this.fetch()
            }
        },
    },

    methods: {
        updateSettings(updateViaPost) {
            let userData = this.$root.Storage.get('user')

            if (!('settings' in userData) || userData.settings === null) {
                userData.settings = {
                    Tables: {}
                }
            }

            userData.settings.Tables[this.path] = JSON.parse(JSON.stringify(this.settings))

            this.$root.Storage.set('user', userData)

            if (updateViaPost === true) {
                this.$forceUpdate()
                this.$root.post(`/user/${userData.id}`, {
                    Settings: JSON.stringify(userData.settings)
                })
            }
        },

        clickRowView(row, currentObject) {
            let source = 'source' in this.$route.params ? this.$route.params.source : []
            
            if (this.label.length > 0) {
                let currentQuery = {}

                if (this.query.pagination.current !== 1) {
                    currentQuery.p = this.query.pagination.current
                }

                if (this.query.pagination.perPage !== 10) {
                    currentQuery.c = this.query.pagination.perPage
                }

                source.push({
                    name: this.$route.name,
                    title: this.label,
                    query: currentQuery,
                })
            }

            let params = {
                for: currentObject,
                id: row.Id,
                folder: 0,
                source: source,
                locale: this.$root.settings('locale'),
            }

            this.$router.push({
                name: 're-details',
                params: params
            })

            // this.$router.push(this.$root.stringPlaceholders(this.view, row))
        },

        clickRowRemove(props) {
            let id = props.row[this.remove.id]
            let objectName = this.remove.desc
            let name = ''
            
            if ('translation' in this.remove) {
                name = this.$root.translation(props.row[this.remove.translation])[this.remove.name]
            } else if ('render' in this.remove) {
                name = this.remove.render(props.row)
            } else {
                name = props.row[this.remove.name]
            }

            if (confirm(this.$root.sPrintF(this.$t('Are you sure you want ro remove %ObjectName% `%Name%` (ID: %Id%)?'), {'%ObjectName%': objectName, '%Name%': name, '%Id%': id}))) {
                this.$root.delete(this.path + '/' + id).then(() => {
                    this.itemsLoading = true
                    this.fetch()
                })
            }
        },

        fetch() {
            let params = {
                page: this.query.pagination.current,
                count: this.query.pagination.perPage,
                locale: this.$root.settings('locale'),
                collection: 1,
            }

            this.$emit('paginated', JSON.parse(JSON.stringify(this.query.pagination)))

            if (this.query.sorting.field != null) {
                params['order[column]'] = this.query.sorting.field
                params['order[dir]'] = this.query.sorting.direction
            }

            if (Object.keys(this.liveFilter).length > 0) {
                for (let key in this.liveFilter) {
                    params['filter[' + key + ']'] = this.liveFilter[key]
                }
            }

            // 👨🏼‍💻 temporary fix until testing with real data
            this.items = []
            this.itemsLoading = true

            this.$root.fetch(this.path + (typeof this.pathAppend === 'string' ? this.pathAppend : ''), params).then(response => {
                let temporary = response

                if (typeof this.object !== 'undefined') {
                    this.query.pagination.total = temporary.pagination.total
                    this.query.pagination.current = temporary.pagination.page
                    this.query.pagination.currentInput = temporary.pagination.page

                    if (this.groupBy) {
                        let groupedRows = []
                        let groupedBy = null
                        temporary[this.object].forEach(row => {
                            if (groupedBy != row[this.groupBy]) {
                                groupedBy = row[this.groupBy]
                                groupedRows.push({...row[this.groupData], ...{ __isGroup: true }})
                            }
                            groupedRows.push(row)
                        })
                        this.items = groupedRows
                    } else {
                        this.items = temporary[this.object]
                    }

                    if (this.mainView) {
                        let toOpen = []
                        this.items.forEach(row => {
                            toOpen.push(row.Id)
                        })
                        this.openedDetail = toOpen
                    }
                }
                this.itemsLoading = false


                if (this.mainView) {
                    if (this.$root.Cookie.get('collectionSelectedGroup')) {
                        this.selectedGroup = this.$root.Cookie.get('collectionSelectedGroup').split(',')
                        this.selectedGroup = this.mapArrayToInt(this.selectedGroup);
                    }

                    if (this.$root.Cookie.get('collectionSelectedProduct')) {
                        this.selectedProduct = this.$root.Cookie.get('collectionSelectedProduct').split(',')
                        let selectedProducts = [];
                        this.selectedProduct.forEach(item => {
                            const parsedProduct = parseInt(item.split('-')[1])
                            selectedProducts.push(parsedProduct);
                        })
                        this.loadSystemDropDown(selectedProducts.join(','))
                    }

                    let groupOptions = []
                    let productOptions = []
                    this.items.forEach(row => {
                        const groupOption = {
                            value: row.Id,
                            label: row.GroupName
                        }
                        groupOptions.push(groupOption)

                        let productOption = {
                            label: row.GroupName,
                            options: [],
                        }
                        if (row.Products && row.Products.length) {
                            row.Products.forEach(subRow => {
                                productOption.options.push({
                                    value: row.Id + '-' + subRow.Id,
                                    label: row.GroupName + ' - ' + subRow.ProductName
                                });
                            })
                        }
                        productOptions.push(productOption)
                    })
                    this.groupOptions = groupOptions
                    this.productOptions = productOptions
                }
            }).catch(() => {
                this.items = []
            })
        },

        mapArrayToInt(data) {
            return data.map(function(str) {
                return parseInt(str);
            });
        },

        changePageOnEnter() {
            this.query.pagination.current = this.query.pagination.currentInput
            this.fetch()
        },

        onGroupSelected(value, preventRecursion = false) {
            this.$root.Cookie.set('collectionSelectedGroup', value.join(','))
            this.selectedGroup = value

            if (!preventRecursion) {
                let selectedProducts = []
                this.selectedProduct.forEach(item => {
                    const parsedValue = parseInt(item.split('-')[0])
                    if (value.includes(parsedValue)) {
                        selectedProducts.push(item)
                    }
                })
                this.onProductSelected(selectedProducts, true)
            }
        },

        onProductSelected(value, preventRecursion = false) {
            this.$root.Cookie.set('collectionSelectedProduct', value.join(','))
            this.selectedProduct = value

            this.$root.Cookie.set('collectionSelectedSystem', 0)
            this.selectedSystem = 0

            if (!preventRecursion) {
                let selectedGroups = []
                let selectedProducts = []
                value.forEach(item => {
                    const parsedGroup = parseInt(item.split('-')[0])
                    if (!selectedGroups.includes(parsedGroup)) {
                        selectedGroups.push(parseInt(item.split('-')[0]))
                    }

                    const parsedProduct = parseInt(item.split('-')[1])
                    selectedProducts.push(parsedProduct);
                })
                this.onGroupSelected(selectedGroups, true)
                this.loadSystemDropDown(selectedProducts.join(','))
            }
        },

        onSystemSelected(value) {
            this.$root.Cookie.set('collectionSelectedSystem', value)
            this.selectedSystem = value
        },

        loadSystemDropDown(filterValue) {
            let params = {
                page: 1,
                count: 10,
                locale: this.$root.settings('locale'),
                collection: 1,
                'filter[ProductId]': filterValue
            }

            this.$root.fetch('system', params).then(response => {
                let systemOptions = []
                this.productOptions.forEach(productOption => {
                    productOption.options.forEach(itemOption => {
                        if (this.selectedProduct.includes(itemOption.value)) {
                            let systemOption = {
                                label: itemOption.label,
                                options: []
                            }

                            const parsedProduct = parseInt(itemOption.value.split('-')[1])
                            response['System'].forEach(system => {
                                if (system.ProductId === parsedProduct) {
                                    systemOption.options.push({
                                        value: system.Id,
                                        label: system.SystemName + ' (' + system.SapId + ')',
                                    });
                                }
                            })
                            systemOptions.push(systemOption)
                        }
                    })
                })
                this.systemOptions = systemOptions

                if (this.$root.Cookie.get('collectionSelectedSystem')) {
                    this.selectedSystem = Number(this.$root.Cookie.get('collectionSelectedSystem'))
                }
            }).catch(() => {
                this.systemOptions = []
            })
        },

        resetCollectionFilter() {
            this.selectedSystem = 0
            this.selectedProduct = []
            this.selectedGroup = []
            this.$root.Cookie.set('collectionSelectedGroup', '')
            this.$root.Cookie.set('collectionSelectedSystem', '')
            this.$root.Cookie.set('collectionSelectedProduct', '')
        },
    },

    created() {
        let user = this.$root.Storage.get('user')

        this.settings = {
          visibilities: {},
          functions: {},
        }

        if ('settings' in user && user.settings !== null) {
            if ('Tables' in user.settings && this.path in user.settings.Tables) {
                let visibilities = user.settings.Tables[this.path].visibilities
                let functions = user.settings.Tables[this.path].functions

                for (let key in visibilities) {
                  if (typeof visibilities[key] !== 'undefined' && key && key !== 'null') {
                    this.settings.visibilities[key] = visibilities[key]
                  }
                }

                for (let key in functions) {
                  if (typeof functions[key] !== 'undefined' && key && key !== 'null') {
                    this.settings.functions[key] = functions[key]
                  }
                }
            }
        }
    },

    mounted() {
        this.fetch()
    },

    updated() {
        let sendSettings = false

        if (!('settings' in this.$root.Storage.get('user')) ||
            this.$root.Storage.get('user').settings === null ||
            !('Tables' in this.$root.Storage.get('user').settings) ||
            !(this.path in this.$root.Storage.get('user').settings.Tables)) {
            sendSettings = true
        }

        this.$root.findChild(this, 'BTable', table => {
            this.settings.functions = this.settings.functions || {}
            let oldSettingsLength = Object.keys(this.settings.functions).length

            if (table.newColumns.length <= 0) {
                return
            }

            table.newColumns.forEach(column => {
                if (column._props.label) {
                    this.settings.functions[column._props.label] = column.$parent.display()
                    if (!(column._props.label in this.settings.visibilities)) {
                      this.settings.visibilities[column._props.label] = true
                    }
                }
            })

            if (oldSettingsLength < Object.keys(this.settings.functions).length) {
                this.$forceUpdate()
                this.$root.findChild(this, 'BgTableToolBar', (child) => {
                    child.$forceUpdate()
                })
            } else if (oldSettingsLength > 0) {
                let newColumns = []

                table.newColumns.forEach(column => {
                    newColumns.push(column.$parent)
                })

                table.$children.forEach(child => {
                    if (child.$options.name.includes('BgColumn')) {
                        while (child.$options.name !== 'BgColumnWrapper' && typeof child !== 'undefined') {
                            child = child.$children[0]
                        }

                        let $parent = newColumns.find(column => {
                            return column.label === child.label
                        })

                        if (this.settings.visibilities[child.label] !== child.renderVisible) {
                            child.renderVisibleValue = this.settings.visibilities[child.label]
                        }

                        if (!child.label || child.label === 'auto') {
                            child.renderVisibleValue = false
                        }

                        if (child._uid !== $parent._uid) {
                            for (let key in $parent.display()) {
                                if (typeof $parent.display(key) !== 'undefined' && child.display(key) != $parent.display(key)) {
                                    child.display(key, $parent.display(key))
                                }
                            }
                        }
                    }
                })
            }
        })

        this.updateSettings(sendSettings)
    },
}
</script>

<template>
    <div>
        <div class="card-toolbar" v-if="mainView">
            <div class="level">
                <div class="level-left level-left--start">
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Sub-brand') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select" :data="groupOptions" :value="selectedGroup" @update="onGroupSelected" :multiple="true"/>
                        </div>
                    </div>
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Product family') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select" :data="productOptions" :value="selectedProduct" @update="onProductSelected" :multiple="true"/>
                        </div>
                    </div>
                    <div class="level-item level-item--columned">
                        <label>{{ $t('System') }}</label>
                        <div class="select">
                            <select2 class="collection-select" :data="systemOptions" :value="selectedSystem" :disabled="!selectedProduct.length" @update="onSystemSelected" placeholder="---"/>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="resetCollectionFilter"
                    v-if="selectedSystem || selectedProduct.length || selectedGroup.length"
                    class="button is-success"
                    style="border-radius: 4px">
                <span class="icon is-small"><i class="mdi mdi-close"></i></span>&nbsp; {{ $t('Reset filter') }}
            </button>
        </div>

        <b-table :data="items"
                 :class="{'detailed': true}"
                 :backend-sorting="false"
                 :opened-detailed="openedDetail"
                 :row-class="(row) => ((mainView && selectedGroup.length && !selectedGroup.includes(row.Id)) || (!mainView && selectedSystemParam && row.Id !== selectedSystemParam)) && 'is-hidden'"
                 detailed
                 detail-key="Id">
            <template slot-scope="props">
                <bg-column :label="$t('Name')" :sort="object + 'Name'">{{ props.row[object + 'Name'] }}</bg-column>
                <bg-column-edited :label="$t('Last changes')" sort="UpdatedOn" :collection="props.row" v-if="!('__isGroup' in props.row) && !noChanges" />
                <bg-column :label="$t('Tools')" v-if="!('__isGroup' in props.row)">
                    <div class="buttons is-pulled-right">
                        <slot name="tools" :row="props.row"></slot>
                        <template v-if="stateView">
                            <button @click="editState = props.row; editStateObject = object" :class="{'button': true, 'is-success': $root.translation(props.row[object + 'I18ns']).StateIdI18n === 1}" type="button" style="border-radius: 50%">
                                <b-icon icon="earth" custom-size="default" style="font-size:22px;margin:1px -11px -2px" />
                            </button>
                        </template>
                        <a :href="'/#/re-details?replaceObject=' + object + '&replaceId=' + props.row.Id" v-if="view.length > 0" class="button is-success" type="button" style="border-radius: 50%" target="_blank">
                            <b-icon icon="pencil" custom-size="default"/>
                        </a>
                    </div>
                </bg-column>
            </template>

            <template #detail="props">
                <template v-if="props.row.Products && detailView === 'Product' && (!selectedGroup.length || selectedGroup.includes(props.row.Id))">
                    <b-table :data="props.row.Products"
                             :class="{'detailed': true}"
                             :backend-sorting="false"
                             :row-class="(row) => selectedProduct.length && !selectedProduct.includes(props.row.Id + '-' + row.Id) && 'is-hidden'"
                             detailed
                             detail-key="Id">
                        <template slot-scope="productProps">
                            <bg-column :label="$t('Name')" sort="ProductName">{{ productProps.row.ProductName }}</bg-column>
                            <bg-column-edited :label="$t('Last changes')" sort="UpdatedOn" :collection="productProps.row" v-if="!('__isGroup' in productProps.row) && !noChanges" />
                            <bg-column :label="$t('Tools')" v-if="!('__isGroup' in productProps.row)">
                                <div class="buttons is-pulled-right">
                                    <slot name="tools" :row="productProps.row"></slot>
                                    <template v-if="stateView">
                                        <button @click="editState = productProps.row; editStateObject = 'Product'" :class="{'button': true, 'is-success': $root.translation(productProps.row.ProductI18ns).StateIdI18n === 1}" type="button" style="border-radius: 50%">
                                            <b-icon icon="earth" custom-size="default" style="font-size:22px;margin:1px -11px -2px" />
                                        </button>
                                    </template>
                                    <a :href="'/#/re-details?replaceObject=Product&replaceId=' + productProps.row.Id" v-if="view.length > 0" class="button is-success" type="button" style="border-radius: 50%" target="_blank">
                                        <b-icon icon="pencil" custom-size="default"/>
                                    </a>
                                </div>
                            </bg-column>
                        </template>

                        <template #detail="productProps">
                            <bg-table-collection view="re-details?for=System&amp;id=${Id}"
                                                 v-if="!selectedProduct.length || selectedProduct.includes(props.row.Id + '-' + productProps.row.Id)"
                                                 :label="label"
                                                 path="system"
                                                 object="System"
                                                 :filter="{ProductId: productProps.row.Id}"
                                                 v-slot="data"
                                                 detail-view="Component"
                                                 :selected-system-param="selectedSystem"
                                                 :state-view="true"/>
                        </template>
                    </b-table>
                </template>
                <template v-if="detailView === 'Component' && (!selectedSystemParam || props.row.Id === selectedSystemParam)">
                    <bg-table view="re-details?for=Component&amp;id=${Id}"
                              :label="label"
                              path="component"
                              object="Component"
                              group-by="ComponentTypeId"
                              group-data="ComponentType"
                              :site-columns="false"
                              :filter="{SystemId: props.row.Id}"
                              :state-view="true"
                              :blank-link="true">

                        <template v-slot="data">
                            <bg-column :label="$t('Item number')" sort="SapId">{{ data.row.SapId }}</bg-column>
                            <bg-column :label="$t('Name')">{{ $root.translation(data.row.ComponentI18ns).Name | truncate(50) }}</bg-column>
                            <bg-column :label="$t('Edge')" sort="Edge" :visible="false">
                                {{ data.row.Edge ? ($root.translation(data.row.Edge.EdgeI18ns).Name) : '-' }}
                            </bg-column>
                        </template>

                        <template v-slot:group="data">
                            <bg-table-group span="5"
                                            :label="$root.translation(data.row.ComponentTypeI18ns, {}, $root.settings('locale')).Name"
                                            :data="data"/>
                        </template>
                    </bg-table>
                </template>
            </template>
        </b-table>

        <template v-if="stateView">
            <bg-state-modal @close="editState = false" :row="editState" :object="editStateObject" :show="editState !== false" />
        </template>
    </div>
</template>
