<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                CollectionType: [],
                translations: {},
                translationTab: 'de_AT',
                translationList: ''
            }
        },

        mounted() {
            if (this.$root.Storage.get('settings') && typeof this.$root.Storage.get('settings').locale !== 'undefined') {
                this.translationTab = this.$root.Storage.get('settings').locale
            }

            if (typeof this.fetched !== 'undefined' && 'FolderI18ns' in this.fetched) {
                let translationList = []

                this.fetched.FolderI18ns.forEach(translation => {
                    translationList.push(translation.Locale)
                    this.translations[translation.Locale] = {
                        Name: translation.Name,
                    }
                })

                this.$root.dataLanguages.forEach(language => {
                    if (typeof this.translations[language.LocaleCode] === 'undefined') {
                        translationList.push(language.LocaleCode)
                        this.translations[language.LocaleCode] = {
                            Name: '',
                        }
                    }
                })

                translationList.sort()
                this.translationList = translationList.join('|')
            }
        },

        async created() {
            if (typeof this.fetched === 'undefined') {
                await this.$root.fetch('collection-type').then(response => {
                    this.CollectionType = response.CollectionType
                })
            }
        },

        methods: {
            beforeSend(content, call) {
                if (this.translationList.length > 4) {
                    content['FolderI18ns'] = []
                    this.translationList.split('|').forEach(localeCode => {
                        if (localeCode.includes('::')) {
                            return
                        }
                        let translation = JSON.parse(JSON.stringify(this.translations[localeCode]))
                        if (translation.Name) {
                            translation.Id = this.fetched.Id
                            translation.Locale = localeCode
                            content['FolderI18ns'].push(translation)
                            delete content['Name--' + localeCode];
                        }
                    })
                }
                content['RequiredCount'] = parseInt(content['RequiredCount'])

                return [content, this.$root.stringPlaceholders(call, content)]
            },
        },
    }
</script>

<template>
    <div>
        <bg-form v-if="create" :button="$t('Create folder')" :beforeSend="'beforeSend'" call="folder"
                 @afterSend="$emit('afterSend')">
            <bg-form-input :label="$t('Name')" name="Name" :size="6" localize="Folder"/>
            <bg-form-input :label="$t('Shortcut')" name="Shortcut"/>
            <bg-form-input :label="$t('Minimum amount')" name="RequiredCount"/>
            <bg-form-select
                    :label="$t('Collection type')"
                    name="CollectionTypeId"
                    :size="6"
                    :placeholder="$t('Please select...')"
                    :options="CollectionType"
                    :option-id="option => option.Id"
                    :option-label="option => ($root.translation(option.CollectionTypeI18ns, null, $root.$i18n.locale).Name ? $root.translation(option.CollectionTypeI18ns, null, $root.$i18n.locale).Name : $root.translation(option.CollectionTypeI18ns, null, 'de_AT').Name)"/>

            <bg-form-input :label="$t('ID')" :size="6" :prefill="$t('Will be created automatically')"/>
        </bg-form>

        <bg-form v-if="!create" :button="$t('Save changes')" :beforeSend="'beforeSend'" call="folder/${__ID}"
                 @afterSend="$emit('afterSend')">
            <bg-form-input :label="$t('ID')" :prefill="fetched.Id"/>
            <bg-form-input :label="$t('Collection type')" :prefill="$root.translation(fetched.CollectionType.CollectionTypeI18ns).Name"/>
            <bg-form-input :label="$t('Shortcut')" name="Shortcut" :prefill="fetched.Shortcut" :readonly="true"/>
            <bg-form-input :label="$t('Minimum amount')" name="RequiredCount" :prefill="fetched.RequiredCount"/>

            <div class="field is-12 column">
                <div class="b-tabs">
                    <nav class="tabs is-boxed" style="margin: 1rem -1rem 0">
                        <ul style="padding: 0 1.25rem">
                            <template v-for="(translation, key) in translationList.split('|')">
                                <li v-if="!translation.includes('::')"
                                    :class="{'is-active': translationTab === translation}" :key="key">
                                    <a @click.prevent="translationTab = translation"
                                       style="padding-left: 0.5rem; padding-right: 0.5rem">
                                        <span>{{ translation.replace('_', '/') }}</span>
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </nav>

                    <template v-for="(translation, key) in translationList.split('|')">
                        <section :key="key" v-if="translationTab === translation">
                            <div class="tab-item columns" style="padding-top: 1rem; padding-bottom: 1rem; flex-wrap: wrap">
                                <bg-form-input
                                        :label="$t('Name')"
                                        :name="'Name--' + translation"
                                        :prefill="translations[translation].Name"
                                        @sync="value => translations[translation]['Name'] = value"
                                        :size="4"/>
                            </div>
                        </section>
                    </template>
                </div>
            </div>
        </bg-form>
    </div>
</template>