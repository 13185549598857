<script>
    import StatusToolPageTable from "@/components/StatusToolPageTable.vue"
    import StatusToolModuleTable from "@/components/StatusToolModuleTable.vue"
    import StatusToolPropertyKeyTable from "@/components/StatusToolPropertyKeyTable.vue"
    import DatePicker from "@/components/DatePicker.vue"
    import * as XLSX from 'xlsx-js-style';

    export default {
        components: {DatePicker, StatusToolPropertyKeyTable, StatusToolPageTable, StatusToolModuleTable},
        data() {
            return {
                types: [
                    {'value': 'pages', 'label': this.$t('Pages')},
                    {'value': 'modules', 'label': this.$t('Modules')},
                    {'value': 'page-properties', 'label': this.$t('Page properties')},
                    {'value': 'module-properties', 'label': this.$t('Module properties')},
                ],
                extraFilters: {
                    exportedFrom: null,
                    exportedTill: null,
                    importedFrom: null,
                    importedTill: null,
                    state: '',
                    search: '',
                },
                savedFilters: [],
                selectedType: 'pages',
                selectedPage: '',
                deactivatePages: true,
                selectedModule: '',
                deactivateModule: true,
                selectedSourceLanguage: '',
                deactivateSourceLanguage: false,
                selectedTargetLanguage: [],
                deactivateTargetLanguage: true,
                withChildren: true,
                pages: [
                    {'value': '', 'label': this.$t('Please select...')},
                ],
                modules: [
                    {'value': '', 'label': this.$t('Please select...')},
                ],
                sourceLanguages: [
                    {'value': '', 'label': this.$t('Please select...')},
                ],
                targetLanguages: [
                    {'value': '', 'label': this.$t('Please select...')},
                ],
                structuredPages: [],
                structuredModules: [],
                closedPages: [],
                closedModules: [],
                neosUrl: '',
                hoveredRow: '',
                clickedRows: [],
                isCompact: false,
                isCompactDisabled: false,
                loading: false,
                filterClosed: true,
                hiddenIdentifiers: [],
                selectedSavedFilter: '',
                savedFilterName: '',
                propertyLabels: {},
                totalCount: 0,
            }
        },

        mounted() {
            this.$store.commit('mainPage', {
                current: this.$t('Status tool'),
                stack: [{
                    'path': '/status-tool',
                    'title': this.$t('Status tool'),
                }]
            })

            if (localStorage.getItem('status-tool-filter')) {
                this.filterClosed = localStorage.getItem('status-tool-filter') === 'closed'
            }

            this.loadFilters()
            this.fetchLanguages()
        },

        computed: {
            tableFullHeight() {
                return (this.totalCount - this.hiddenIdentifiers.length) > 30
            },
            targetDimensions() {
                let newList = []
                for (let index in this.targetLanguages) {
                    if (this.targetLanguages[index]['value'] !== this.selectedSourceLanguage) {
                        newList.push(this.targetLanguages[index])
                    }
                }

                return newList
            },
            filterExists() {
                return this.extraFilters.search || this.extraFilters.state || this.extraFilters.exportedFrom || this.extraFilters.exportedTill || this.extraFilters.importedFrom  || this.extraFilters.importedTill
            },
            filterDisabled() {
                return ['page-properties', 'module-properties'].includes(this.selectedType)
            },
        },

        methods: {
            isAdmin() {
                let user = this.$root.Storage.get('user')

                return user && user.roleShortcut === 'admin'
            },
            getNeosUsername() {
                let user = this.$root.Storage.get('user')

                return user && user.neosUsername
            },
            fetchLanguages() {
                const vm = this
                this.$root.post('neos', {
                    'action': 'getLanguages',
                }).then((response) => {
                    vm.sourceLanguages = [
                        {'value': '', 'label': this.$t('Please select...')},
                        ...response.languages
                    ]
                    vm.targetLanguages = [
                        {'value': '', 'label': this.$t('Please select...')},
                        ...response.dimensions
                    ]
                })
            },
            fetchPages() {
                if (this.selectedSourceLanguage) {
                    const vm = this
                    this.$root.post('neos', {
                        'action': 'getNeosPagesData',
                        'sourceLanguage': this.selectedSourceLanguage,
                    }).then((response) => {
                        vm.pages = [
                            {'value': '', 'label': vm.selectedType === 'pages' ? this.$t('Select all') : this.$t('Please select...')},
                            ...response
                        ]
                        vm.deactivatePages = false
                    })
                }
            },
            fetchModules() {
                if (this.selectedSourceLanguage && (this.selectedType === 'modules' || this.selectedType === 'module-properties') && this.selectedPage) {
                    const vm = this
                    this.$root.post('neos', {
                        'action': 'getNeosModuleData',
                        'sourceLanguage': this.selectedSourceLanguage,
                        'selectedPage': this.selectedPage,
                    }).then((response) => {
                        vm.modules = [
                            {'value': '', 'label': this.$t('Please select...')},
                            ...response
                        ]
                    })
                }
            },
            fetchData() {
                this.hiddenIdentifiers = []
                this.closedPages = []
                this.clickedRows = []
                this.hoveredRow = ''
                this.structuredModules = []
                this.structuredPages = []
                const vm = this
                if (this.selectedTargetLanguage.length && (this.selectedType === 'pages' || (this.selectedType === 'page-properties' && this.selectedPage))) {
                    this.loading = true
                    this.$root.post('neos', {
                        'action': 'getStructuredPageData',
                        'type': this.selectedType,
                        'sourceLanguage': this.selectedSourceLanguage,
                        'targetLanguages': this.selectedTargetLanguage,
                        'selectedPage': this.selectedPage,
                        'withChildren': this.withChildren,
                    }).then((response) => {
                        vm.structuredPages = response.pages
                        vm.neosUrl = response.neosUrl
                        vm.propertyLabels = response.propertyLabels
                        vm.totalCount = response.totalCount
                        this.loading = false
                    })
                } else if (this.selectedTargetLanguage.length && this.selectedPage && (this.selectedType === 'modules' || (this.selectedType === 'module-properties' && this.selectedModule))) {
                    this.loading = true
                    this.$root.post('neos', {
                        'action': 'getStructuredModuleData',
                        'type': this.selectedType,
                        'sourceLanguage': this.selectedSourceLanguage,
                        'targetLanguages': this.selectedTargetLanguage,
                        'selectedPage': this.selectedPage,
                        'selectedModule': this.selectedModule,
                        'withChildren': this.withChildren,
                    }).then((response) => {
                        vm.structuredModules = response.modules
                        vm.neosUrl = response.neosUrl
                        vm.propertyLabels = response.propertyLabels
                        vm.totalCount = response.totalCount
                        this.loading = false
                    })
                }
            },
            getFullLanguageName(dimension) {
                for (let index in this.sourceLanguages) {
                    if (this.sourceLanguages[index]['value'] === dimension) {
                        return this.sourceLanguages[index]['label']
                    }
                }

                return dimension
            },
            handleRowHover(value) {
                this.hoveredRow = value
            },
            changeExpandedPages(value) {
                if (this.closedPages.includes(value)) {
                    const index = this.closedPages.indexOf(value)
                    if (index !== -1) {
                        this.closedPages.splice(index, 1)
                    }
                } else {
                    this.closedPages.push(value)
                }
            },
            changeExpandedModules(value) {
                if (this.closedModules.includes(value)) {
                    const index = this.closedModules.indexOf(value)
                    if (index !== -1) {
                        this.closedModules.splice(index, 1)
                    }
                } else {
                    this.closedModules.push(value)
                }
            },
            changeClickedRows(value) {
                if (this.clickedRows.includes(value)) {
                    const index = this.clickedRows.indexOf(value)
                    if (index !== -1) {
                        this.clickedRows.splice(index, 1)
                    }
                } else {
                    this.clickedRows.push(value)
                }
            },
            resetFilter() {
                this.selectedType = 'pages'
                this.selectedSourceLanguage = ''
                this.selectedTargetLanguage = []
                this.withChildren = true
                this.closedPages = []
                this.pages = []
                this.modules = []
                this.resetExtraFilters()
            },
            resetExtraFilters() {
                this.hiddenIdentifiers = []
                this.extraFilters.exportedFrom = ''
                this.extraFilters.exportedTill = ''
                this.extraFilters.importedFrom = ''
                this.extraFilters.importedTill = ''
                this.extraFilters.search = ''
                this.extraFilters.state = ''
            },
            handleShowIdentifier(identifier) {
                const index = this.hiddenIdentifiers.indexOf(identifier)
                if (index !== -1) {
                    this.hiddenIdentifiers.splice(index, 1)
                }
            },
            handleHideIdentifier(identifier) {
                if (!this.hiddenIdentifiers.includes(identifier)) {
                    this.hiddenIdentifiers.push(identifier)
                }
            },
            saveFilter() {
                let currentFilter = {
                    mainFilter: {
                        selectedType: this.selectedType,
                        selectedSourceLanguage: this.selectedSourceLanguage,
                        selectedTargetLanguage: this.selectedTargetLanguage,
                        selectedPage: this.selectedPage,
                        selectedModule: this.selectedModule,
                        withChildren: this.withChildren,
                    },
                    extraFilter: this.extraFilters,
                }

                let userData = this.$root.Storage.get('user')
                if (!('settings' in userData) || userData.settings === null) {
                    userData.settings = {
                        StatusTool: {
                            filters: {},
                        }
                    }
                }
                if (!('StatusTool' in userData.settings) || userData.settings.StatusTool === null) {
                    userData.settings.StatusTool = {
                        filters: {},
                    }
                }

                userData.settings.StatusTool.filters[this.savedFilterName] = currentFilter

                this.$root.Storage.set('user', userData)
                this.loadFilters()
                this.$root.post(`/user/${userData.id}`, {
                    Settings: JSON.stringify(userData.settings)
                })
            },
            loadFilters() {
                let userData = this.$root.Storage.get('user')
                let additionalFilter = []
                if (('settings' in userData) && ('StatusTool' in userData.settings) && ('filters' in userData.settings.StatusTool)) {
                    for (const filterName in userData.settings.StatusTool.filters) {
                        additionalFilter.push({value: filterName, label: filterName})
                    }
                }

                this.savedFilters = [
                    ...[
                        {'value': '', 'label': this.$t('Apply filter...')},
                        {'value': 'new', 'label': this.$t('Save filter options...')},
                    ],
                    ...additionalFilter
                ]
            },
            deleteFilter() {
                let userData = this.$root.Storage.get('user')
                if (this.selectedSavedFilter && this.selectedSavedFilter !== 'new' && typeof userData.settings.StatusTool.filters[this.selectedSavedFilter] !== 'undefined') {
                    delete userData.settings.StatusTool.filters[this.selectedSavedFilter]
                    this.$root.Storage.set('user', userData)
                    this.$root.post(`/user/${userData.id}`, {
                        Settings: JSON.stringify(userData.settings)
                    })
                    this.resetFilter()
                    this.selectedSavedFilter = ''
                    this.savedFilterName = ''
                }
            },
            exportToFile() {
                const preparedData = ['pages', 'modules'].includes(this.selectedType) ? this.prepareDataForExport() : this.preparePropertiesDataForExport()
                const preparedDataArray = Object.values(preparedData)

                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(preparedDataArray);
                XLSX.utils.book_append_sheet(
                    workbook,
                    worksheet,
                    'Data',
                    true
                )

                // generate coll name list
                let colNames = [];
                const colLength = Object.keys(this.structuredPages).length ? Object.keys(this.structuredPages).length : Object.keys(this.structuredModules).length
                for (let i = 0; i < (colLength + 1); i++) {
                    colNames.push(XLSX.utils.encode_cell({c: i, r: 0}))
                }

                // set styling
                for (const itm of colNames) {
                    if (worksheet[itm]) {
                        worksheet[itm].s = {
                            font: {
                                bold: true,
                            },
                        }
                    }
                }
                XLSX.writeFile(workbook, 'export.xlsx')
            },
            prepareDataForExport() {
                let data = {}
                const dataToExport = Object.keys(this.structuredPages).length ? this.structuredPages : this.structuredModules
                if (Object.keys(dataToExport).length) {
                    for (const locale in dataToExport) {
                        const mainPage = dataToExport[locale]

                        let item = {identifier: mainPage.identifier}
                        if (typeof data[mainPage.identifier] !== 'undefined') {
                            item = data[mainPage.identifier]
                        }
                        let level = 0
                        let title = (typeof mainPage.properties !== 'undefined' ? mainPage.properties.title : '(not exists)')
                        if (this.selectedType === 'modules') {
                            title = typeof mainPage.nodetype !== 'undefined' ? mainPage.nodetype : '(not exists)'
                        }
                        item[locale] = title
                        data[mainPage.identifier] = item
                        data = this.readChildrenForExport(data, locale, mainPage.children, level + 1)
                    }
                }

                return data
            },
            preparePropertiesDataForExport() {
                let data = {}
                const dataToExport = Object.keys(this.structuredPages).length ? this.structuredPages : this.structuredModules
                if (Object.keys(dataToExport).length) {
                    for (const locale in dataToExport) {
                        const mainPage = dataToExport[locale]

                        for (const identifier in mainPage.properties) {
                            let item = {identifier: identifier}
                            if (typeof data[identifier] !== 'undefined') {
                                item = data[identifier]
                            }
                            let values = mainPage.properties[identifier]
                            if (typeof values.value !== 'undefined') {
                                values = values.value
                                values = Array.isArray(values) ? values.join('|') : values
                            }
                            item[locale] = values
                            data[identifier] = item
                        }
                    }
                }

                return data
            },
            readChildrenForExport(data, locale, children, level) {
                if (typeof children === 'undefined' || !Object.keys(children).length) {
                    return data
                }

                for (const identifier in children) {
                    const page = children[identifier]
                    if (!this.hiddenIdentifiers.includes(identifier)) {
                        let item = {identifier: page.identifier}
                        if (typeof data[page.identifier] !== 'undefined') {
                            item = data[page.identifier]
                        }
                        let title = ('—'.repeat(level)) + ' ' + (typeof page.properties !== 'undefined' ? page.properties.title : '(not exists)')
                        if (this.selectedType === 'modules') {
                            title = ('—'.repeat(level)) + ' ' + (typeof page.nodetype !== 'undefined' ? page.nodetype : '(not exists)')
                        }
                        item[locale] = title
                        data[page.identifier] = item
                    }
                    data = this.readChildrenForExport(data, locale, page.children, level + 1)
                }

                return data;
            }
        },

        watch: {
            selectedType(newValue) {
                this.selectedModule = ''
                this.structuredModules = []
                this.structuredPages = []
                this.deactivateModule = true
                this.isCompactDisabled = false
                if (newValue === 'pages') {
                    // do nothing
                } else if (newValue === 'modules') {
                    this.deactivateModule = false
                    if (this.selectedPage) {
                        this.fetchModules()
                    }
                } else if (newValue === 'page-properties') {
                    this.isCompact = false
                    this.isCompactDisabled = true
                } else if (newValue === 'module-properties') {
                    this.isCompact = false
                    this.isCompactDisabled = true
                    this.deactivateModule = false
                    if (this.selectedPage) {
                        this.fetchModules()
                    }
                }
            },
            selectedSourceLanguage(newValue) {
                this.deactivateTargetLanguage = !newValue
                this.selectedTargetLanguage = !newValue ? [] : this.selectedTargetLanguage
                this.fetchPages()
            },
            selectedPage(newValue) {
                this.selectedModule = ''
                if (newValue) {
                    this.fetchModules()
                } else {
                    this.modules = []
                }
            },
            filterClosed(newValue) {
                localStorage.setItem('status-tool-filter', newValue ? 'closed' : 'opened')
            },
            savedFilterName(newValue) {
                this.savedFilterName = newValue.replace(/[\W]+/g, '')
            },
            selectedSavedFilter(newValue) {
                this.savedFilterName = ''
                if (!newValue) {
                    this.resetFilter()
                } else if (newValue && newValue !== 'new') {
                    this.savedFilterName = newValue
                    let userData = this.$root.Storage.get('user')
                    if (typeof userData.settings.StatusTool.filters[newValue] !== 'undefined') {
                        this.resetFilter()
                        this.selectedType = userData.settings.StatusTool.filters[newValue].mainFilter.selectedType
                        this.selectedSourceLanguage = userData.settings.StatusTool.filters[newValue].mainFilter.selectedSourceLanguage
                        this.selectedTargetLanguage = userData.settings.StatusTool.filters[newValue].mainFilter.selectedTargetLanguage
                        this.withChildren = userData.settings.StatusTool.filters[newValue].mainFilter.withChildren
                        this.extraFilters = userData.settings.StatusTool.filters[newValue].extraFilter

                        const _that = this
                        if (userData.settings.StatusTool.filters[newValue].mainFilter.selectedPage) {
                            _that.fetchPages()
                            let pagesIntervalTimes = 0
                            let pagesInterval = setInterval(function() {
                                if (pagesIntervalTimes === 10) {
                                    clearInterval(pagesInterval)
                                    pagesInterval = null
                                    alert('Selected filter could not be applied fully.')
                                }

                                if (_that.pages.length) {
                                    _that.selectedPage = userData.settings.StatusTool.filters[newValue].mainFilter.selectedPage
                                    _that.fetchModules()
                                    clearInterval(pagesInterval)
                                    pagesInterval = null
                                }
                                pagesIntervalTimes++
                            }, 500)
                        }

                        if (userData.settings.StatusTool.filters[newValue].mainFilter.selectedModule) {
                            let modulesIntervalTimes = 0
                            let modulesInterval = setInterval(function() {
                                if (modulesIntervalTimes === 10) {
                                    clearInterval(modulesInterval)
                                    modulesInterval = null
                                    alert('Selected filter could not be applied fully.')
                                }

                                if (_that.selectedPage && _that.modules.length) {
                                    _that.selectedModule = userData.settings.StatusTool.filters[newValue].mainFilter.selectedModule
                                    clearInterval(modulesInterval)
                                    modulesInterval = null
                                }
                                modulesIntervalTimes++
                            }, 500)
                        }
                    }
                }
            },
        }
    }
</script>

<template>
    <div class="bg-status-tool">
        <section class="section" v-if="isAdmin()">
            <div class="container-fluid">
                <div class="columns">
                    <div class="column is-2">
                        <bg-form-select
                            :label="$t('Type')"
                            name="--random--1"
                            :addColumnClass="false"
                            :placeholder="$t('Please select...')"
                            :size="2"
                            aria-required="true"
                            :options="types"
                            :prefill="selectedType"
                            @sync="value => selectedType = value"
                            :option-id="option => option.value"
                            :option-label="option => option.label"/>
                    </div>
                    <div class="column is-2">
                        <bg-form-select
                            :label="$t('Source language')"
                            name="--random--4"
                            :addColumnClass="false"
                            :placeholder="$t('Please select...')"
                            :size="2"
                            aria-required="true"
                            :options="sourceLanguages"
                            :disabled="deactivateSourceLanguage"
                            :prefill="selectedSourceLanguage"
                            @sync="value => selectedSourceLanguage = value"
                            :option-id="option => option.value"
                            :option-label="option => option.label"/>
                    </div>
                    <div class="column is-2">
                        <label class="label">{{ $t('Target language') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select collection-select--inline"
                                     :disabled="deactivateTargetLanguage"
                                     :data="targetDimensions"
                                     @update="value => selectedTargetLanguage = value"
                                     :value="selectedTargetLanguage"
                                     :multiple="true"/>
                        </div>
                    </div>
                    <div class="column is-2">
                        <label class="label">{{ $t('Page') }}</label>
                        <div class="select is-single">
                            <select2 class="collection-select collection-select--inline"
                                     :disabled="deactivatePages"
                                     :data="pages"
                                     @update="value => selectedPage = value"
                                     :value="selectedPage"
                                     :multiple="false"/>
                        </div>
                    </div>
                    <div class="column is-2">
                        <bg-form-select
                            :label="$t('Module')"
                            name="--random--3"
                            :addColumnClass="false"
                            :placeholder="$t('Please select...')"
                            :size="2"
                            aria-required="true"
                            :options="modules"
                            :disabled="deactivateModule"
                            :prefill="selectedModule"
                            @sync="value => selectedModule = value"
                            :option-id="option => option.value"
                            :option-label="option => option.label"/>
                    </div>
                    <div class="column is-2">
                        <br>
                        <br>
                        <b-checkbox v-model="withChildren" type="is-success">
                            {{ $t('With subordinates') }}
                        </b-checkbox>
                    </div>
                </div>
                <div :class="{'columns': true, 'is-hidden': filterClosed}">
                    <div class="column is-2">
                        <label class="label">{{ $t('Trados export from') }}</label>
                        <date-picker v-model="extraFilters.exportedFrom" format="d.m.Y" placeholder="dd.mm.YY" :disabled="filterDisabled"/>
                    </div>
                    <div class="column is-2">
                        <label class="label">{{ $t('Trados export till') }}</label>
                        <date-picker v-model="extraFilters.exportedTill" format="d.m.Y" placeholder="dd.mm.YY" :disabled="filterDisabled"/>
                    </div>
                    <div class="column is-2">
                        <label class="label">{{ $t('Trados import from') }}</label>
                        <date-picker v-model="extraFilters.importedFrom" format="d.m.Y" placeholder="dd.mm.YY" :disabled="filterDisabled"/>
                    </div>
                    <div class="column is-2">
                        <label class="label">{{ $t('Trados import till') }}</label>
                        <date-picker v-model="extraFilters.importedTill" format="d.m.Y" placeholder="dd.mm.YY" :disabled="filterDisabled"/>
                    </div>
                </div>
                <div :class="{'columns': true, 'is-hidden': filterClosed}">
                    <div class="column is-3">
                        <label class="label">{{ $t('Word filter') }}</label>
                        <input class="input" v-model="extraFilters.search" :placeholder="$t('Search everywhere...')" :title="$t('Advanced search: meta&&index (=AND) or index||follow (=OR)...')"/>
                    </div>
                    <div class="column is-4">
                        <label class="label">{{ $t('Settings status') }}</label>
                        <div class="control">
                            <b-radio v-model="extraFilters.state" name="filters_state" type="is-success" native-value="" :disabled="filterDisabled">
                                <span>{{ $t('All') }}</span>
                            </b-radio>
                            <b-radio v-model="extraFilters.state" name="filters_state" type="is-success" native-value="translated" :disabled="filterDisabled">
                                <i class="mdi mdi-translate" style="margin-right:.25rem;"></i>
                                <span>{{ $t('Translated') }}</span>
                            </b-radio>
                            <b-radio v-model="extraFilters.state" name="filters_state" type="is-success" native-value="individualElement" :disabled="filterDisabled">
                                <i class="mdi mdi-creation" style="margin-right:.25rem;"></i>
                                <span>{{ $t('Individualized') }}</span>
                            </b-radio>
                            <b-radio v-model="extraFilters.state" name="filters_state" type="is-success" native-value="globalElement" :disabled="filterDisabled">
                                <i class="mdi mdi-content-copy" style="margin-right:.25rem;"></i>
                                <span>{{ $t('Mirrored') }}</span>
                            </b-radio>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-1">
                        <button class="button is-success" :disabled="!(selectedSourceLanguage && selectedTargetLanguage.length)" style="width:100%;border-radius:4px;" @click="fetchData">
                            {{ $t('Filter') }}
                        </button>
                    </div>
                    <div class="column is-1">
                        <button class="button is-light"
                                :disabled="!(Object.keys(structuredModules).length || Object.keys(structuredPages).length)"
                                style="width:100%;border-radius:4px;"
                                @click="exportToFile">
                            {{ $t('Export') }}
                        </button>
                    </div>
                    <div class="column is-1 is-bordered-right">
                        <b-checkbox v-model="isCompact" :disabled="isCompactDisabled" type="is-success" style="margin-top:.5rem">
                            {{ $t('Compact') }}
                        </b-checkbox>
                    </div>
                    <div class="column is-2">
                        <b-select v-model="selectedSavedFilter">
                            <template v-for="(option, index) in savedFilters">
                                <option
                                    :key="index"
                                    :value="option.value">
                                    {{ option.label }}
                                </option>
                            </template>
                        </b-select>
                    </div>
                    <div class="column is-2">
                        <input class="input" v-model="savedFilterName" v-if="selectedSavedFilter" :disabled="selectedSavedFilter !== 'new'" :placeholder="$t('Add filter name...')" minlength="5"/>
                    </div>
                    <div class="column is-1">
                        <button class="button is-success" v-if="selectedSavedFilter" :disabled="savedFilterName.length <= 4" style="width:100%;border-radius:4px;" @click="saveFilter()">
                            {{ $t('Save') }}
                        </button>
                    </div>
                    <div class="column is-1">
                        <button class="button is-danger" v-if="selectedSavedFilter && selectedSavedFilter !== 'new'" style="width:100%;border-radius:4px;" @click="deleteFilter()">
                            {{ $t('Delete') }}
                        </button>
                    </div>
                    <div class="column is-3 has-text-right">
                        <a href="#" class="link-action" style="width:100%;border-radius:4px;" @click.prevent="selectedSavedFilter = '';resetFilter();">
                            <span>{{ $t('Reset filter') }}</span>
                            <i class="mdi mdi-close"></i>
                        </a>
                        <a href="#" class="link-action" style="width:100%;border-radius:4px;" @click.prevent="filterClosed = !filterClosed">
                            <span v-if="filterClosed">{{ $t('Open filter') }}</span>
                            <span v-else>{{ $t('Close filter') }}</span>
                            <i v-if="filterClosed" class="mdi mdi-chevron-down"></i>
                            <i v-else class="mdi mdi-chevron-up"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <template v-else>
            {{ $t('You have not enough permissions to see this area') }}
        </template>

        <section v-if="isAdmin()">
            <div class="container-fluid">
                <hr class="green-bar">
            </div>
        </section>

        <section class="section" slot="empty" v-if="isAdmin() && loading">
            <div class="content has-text-grey has-text-centered">
                <br><br>
                <p>{{ $t('Entries are loading...') }}</p>
            </div>
        </section>

        <section class="section" v-if="isAdmin()">
            <div class="container-fluid">
                <div :class="{'bg-status-tool__wrapper': true, 'closed': filterClosed}">
                    <div :class="{'bg-status-tool__content': true, 'full-height': tableFullHeight}">
                        <template v-if="selectedType === 'page-properties' && Object.keys(structuredPages).length">
                            <status-tool-property-key-table :property-labels="propertyLabels"
                                                            :element="structuredPages[selectedSourceLanguage]"
                                                            @row-hover="handleRowHover"
                                                            @click-row="changeClickedRows"
                                                            :hidden-identifiers="hiddenIdentifiers"
                                                            :clicked-rows="clickedRows"
                                                            :hovered-row="hoveredRow"/>
                        </template>
                        <template v-if="selectedType === 'module-properties' && Object.keys(structuredModules).length">
                            <status-tool-property-key-table :property-labels="propertyLabels"
                                                            :element="structuredModules[selectedSourceLanguage]"
                                                            @row-hover="handleRowHover"
                                                            @click-row="changeClickedRows"
                                                            :hidden-identifiers="hiddenIdentifiers"
                                                            :clicked-rows="clickedRows"
                                                            :hovered-row="hoveredRow"/>
                        </template>
                        <template v-for="(structuredPage, language, index) in structuredPages">
                            <status-tool-page-table :heading-long="getFullLanguageName(language)"
                                                    :locale="language"
                                                    :key="language"
                                                    :display-type="selectedType"
                                                    :page="structuredPage"
                                                    @change-expand="changeExpandedPages"
                                                    @click-row="changeClickedRows"
                                                    @row-hover="handleRowHover"
                                                    @show-identifier="handleShowIdentifier"
                                                    @hide-identifier="handleHideIdentifier"
                                                    :filters="extraFilters"
                                                    :hidden-identifiers="index === 0 ? [] : hiddenIdentifiers"
                                                    :filter-exists="!!filterExists"
                                                    :filter-apply="index === 0"
                                                    :closed-pages="closedPages"
                                                    :clicked-rows="clickedRows"
                                                    :hovered-row="hoveredRow"
                                                    :is-sticky="index === 0"
                                                    :neos-username="getNeosUsername()"
                                                    :is-compact="index !== 0 || ['page-properties', 'module-properties'].includes(selectedType) ? isCompact : false"
                                                    :neos-url="neosUrl"
                                                    :property-labels="propertyLabels"/>
                        </template>
                        <template v-for="(structuredModule, language, index) in structuredModules">
                            <status-tool-module-table :heading-long="getFullLanguageName(language)"
                                                      :locale="language"
                                                      :key="language"
                                                      :display-type="selectedType"
                                                      :module="structuredModule"
                                                      @change-expand="changeExpandedModules"
                                                      @click-row="changeClickedRows"
                                                      @row-hover="handleRowHover"
                                                      @show-identifier="handleShowIdentifier"
                                                      @hide-identifier="handleHideIdentifier"
                                                      :hidden-identifiers="index === 0 ? [] : hiddenIdentifiers"
                                                      :filters="extraFilters"
                                                      :filter-exists="!!filterExists"
                                                      :filter-apply="index === 0"
                                                      :hovered-row="hoveredRow"
                                                      :is-sticky="index === 0"
                                                      :closed-modules="closedModules"
                                                      :clicked-rows="clickedRows"
                                                      :is-compact="index !== 0 || ['page-properties', 'module-properties'].includes(selectedType) ? isCompact : false"
                                                      :neos-username="getNeosUsername()"
                                                      :neos-url="neosUrl"
                                                      :property-labels="propertyLabels"/>
                        </template>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
