<script>
    import axios from 'axios';
    import moment from 'moment'

    export default {
        data() {
            return {
                adminStatusInterval: null,
                exportLoading: false,
                uploadsLoading: false,
                commandProcessing: {
                    default: false,
                    redis: false,
                    sqlite: false,
                    reSqlite: false,
                    tradosImport: false,
                    uploads: false,
                    db: false,
                    sap: false,
                    neos: false,
                    persistent: false,
                },
                errorMessage: '',
                successMessage: '',
                currentTab: 'mt',
                importType: 'full',
                languagesSelection: '',
                copySourceLanguage: '',
                copyLanguagesSelection: '',
                copyFolderSelection: '',
                availableLanguages: [],
                folders: [],
                uploadsStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                dbStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                sapStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                neosStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                persistentStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                massactionStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                languages: [],
                copyLanguages: [],
                copyFolders: [],
                copyUpdate: null,
                copyUpdateCompleted: null,
                disableCopyButton: true,
            }
        },

        mounted() {
            this.$store.commit('mainPage', {
                current: this.$t('Admin'),
                stack: [{
                    'path': '/admin',
                    'title': this.$t('Admin'),
                }]
            })
            if (this.isAdmin()) {
                this.fetchData()
            }
            this.adminStatusInterval = setInterval(this.fetchData, 10000)
            this.$root.fetch('folder', {count: -1}).then(response => {
                this.folders = response.Folder
            })
        },

        methods: {
            isAdmin() {
                let user = this.$root.Storage.get('user')

                return user && user.roleShortcut === 'admin'
            },
            fetchData() {
                axios
                    .get('/api/admin-status')
                    .then((response) => {
                        if (Array.isArray(response.data.importStatus)) {
                            this.availableLanguages = response.data.importStatus
                            this.uploadsStatus = response.data.uploadsStatus
                            this.dbStatus = response.data.dbStatus
                            this.sapStatus = response.data.sapStatus
                            this.neosStatus = response.data.neosStatus
                            this.persistentStatus = response.data.persistentStatus
                            this.massactionStatus = response.data.massactionStatus
                        } else {
                            this.errorMessage = response.data
                            setTimeout(this.resetMessage, 4000)
                        }
                    })
                    .catch(() => {
                        this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                        setTimeout(this.resetMessage, 4000)
                    })
            },
            resetMessage() {
                this.errorMessage = ''
                this.successMessage = ''
            },
            exportData() {
                this.resetMessage()
                if (confirm(this.$t('Are you sure you want to do this action?'))) {
                    this.exportLoading = true
                    axios
                        .get('/api/export')
                        .then((response) => {
                            this.exportLoading = false
                            if (response.data) {
                                this.errorMessage = response.data
                            } else {
                                this.successMessage = this.$t('Export was successful.')
                            }
                            setTimeout(this.resetMessage, 4000)
                        })
                        .catch(() => {
                            this.exportLoading = false
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            setTimeout(this.resetMessage, 4000)
                        })
                }
            },
            importData() {
                this.resetMessage()
                let isValid = true
                if (this.languagesSelection === 'selection' && !this.languages.length) {
                    this.errorMessage = this.$t('Please select at least one language.')
                    setTimeout(this.resetMessage, 4000)
                    isValid = false
                }

                if (isValid && confirm(this.$t('Are you sure you want to do this action?'))) {
                    axios
                        .get('/api/import' + '?type=' + this.importType + '&languages=' + (this.languages.length ? this.languages.join(',') : ''))
                        .then((response) => {
                            if (response.data) {
                                this.errorMessage = response.data
                            } else {
                                this.successMessage = this.$t('Import was started successfully.')
                                this.fetchData()
                            }
                            setTimeout(this.resetMessage, 4000)
                        })
                }
            },
            executeCommand(command) {
                this.resetMessage()
                if (confirm(this.$t('Are you sure you want to do this action?'))) {
                    this.commandProcessing[command] = true
                    axios
                        .get('/api/command' + '?command=' + command)
                        .then((response) => {
                            this.commandProcessing[command] = false
                            if (response.data) {
                                this.successMessage = response.data
                            } else {
                                this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            }
                            this.fetchData()
                            setTimeout(this.resetMessage, 4000)
                        })
                        .catch(() => {
                            this.commandProcessing[command] = false
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            setTimeout(this.resetMessage, 4000)
                        })
                }
            },
            changeTab(tabName) {
                this.currentTab = tabName
            },
            view(tab) {
                return this.currentTab === tab
            },
            getDuration: function (start, end) {
                if (!end) {
                    return '-';
                }

                let duration = moment.duration(moment(end).diff(moment(start)));
                return (duration.asMinutes()).toFixed(2) + 'm';
            },
            getPlatform() {
                let stage = process.env.NODE_ENV
                if (stage === 'production' && window.location.hostname !== 'data.bg-graspointner.com') {
                    stage = 'staging'
                }

                return stage;
            },
            isAnyStatusActive() {
                return !!(this.sapStatus.start || this.sapStatus.processing || this.uploadsStatus.start ||
                    this.uploadsStatus.processing || this.dbStatus.start || this.dbStatus.processing ||
                    this.neosStatus.start || this.neosStatus.processing || this.persistentStatus.start ||
                    this.persistentStatus.processing)
            },
            updateCopyData() {
                axios
                    .get(
                        '/api/file?action=copyFileI18nCheck&sourceLanguage=' + this.copySourceLanguage +
                        '&targetLanguages=' + (this.copyLanguagesSelection === 'all' ? '*' : this.copyLanguages.join(',')) +
                        '&sourceFolders=' + (this.copyFolderSelection === 'all' ? '*' : this.copyFolders.join(','))
                    )
                    .then((response) => {
                        if (response.data.success) {
                            this.disableCopyButton = false
                            this.copyUpdate = response.data.data
                        } else {
                            this.disableCopyButton = true
                            this.copyUpdate = null
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            console.log(response.data.message)
                        }
                    })
                    .catch(() => {
                        this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                        setTimeout(this.resetMessage, 4000)
                    })
            },
            performCopyData() {
                if (confirm(this.$t('Do you want to start copy action?'))) {
                    axios
                        .post(
                            '/api/file',
                            {
                                'action': 'copyFileI18n',
                                'sourceLanguage': this.copySourceLanguage,
                                'targetLanguages': (this.copyLanguagesSelection === 'all' ? '*' : this.copyLanguages.join(',')),
                                'sourceFolders': (this.copyFolderSelection === 'all' ? '*' : this.copyFolders.join(',')),
                            }
                        )
                        .then((response) => {
                            this.copyUpdate = null
                            if (response.data.success) {
                                this.copyUpdateCompleted = response.data.data
                                const _that = this
                                setTimeout(function () {
                                    _that.copyUpdateCompleted = null
                                }, 10000)
                            } else {
                                this.disableCopyButton = true
                                this.copyUpdateCompleted = null
                                this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                                console.log(response.data.message)
                            }
                            this.copySourceLanguage = ''
                            this.copyLanguagesSelection = ''
                            this.copyFolderSelection = ''
                            this.fetchData()
                        })
                        .catch(() => {
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            setTimeout(this.resetMessage, 4000)
                        })
                }
            }
        },

        filters: {
            date: function (value) {
                if (!value) {
                    return '-';
                }

                return moment(value).format('DD.MM.YYYY HH:mm:ss')
            },
            dateUnix: function (value) {
                if (!value) {
                    return '-';
                }

                return moment.unix(value).format('DD.MM.YYYY HH:mm:ss')
            },
        },

        computed: {
            disableCopyFileI18nButton() {
                return !this.copySourceLanguage || (!this.copyLanguages.length && !this.copyLanguagesSelection) || (!this.copyFolders.length && !this.copyFolderSelection);
            },
        },

        watch: {
            copyLanguagesSelection(newValue) {
                this.copyLanguages = []
                if (newValue) {
                    this.availableLanguages.forEach(language => {
                        this.copyLanguages.push(language.locale)
                    })
                }
            },
            copyFolderSelection(newValue) {
                this.copyFolders = []
                if (newValue) {
                    this.folders.forEach(folder => {
                        this.copyFolders.push(folder.Id)
                    })
                }
            },
            copyFolders(newValue) {
                if (!this.disableCopyFileI18nButton && newValue.length) {
                    this.updateCopyData()
                }
            },
            copyLanguages(newValue) {
                if (!this.disableCopyFileI18nButton && newValue.length) {
                    this.updateCopyData()
                }
            },
        }
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <div class="b-tabs" v-if="isAdmin">
                <nav class="tabs is-boxed">
                    <ul>
                        <li :class="{ 'is-active': currentTab === 'mt' }">
                            <a @click="changeTab('mt')"><span>{{ $t('Mapping Tool Project') }}</span></a>
                        </li>
                        <li :class="{ 'is-active': currentTab === 'neos' }">
                            <a @click="changeTab('neos')"><span>{{ $t('NEOS Project') }}</span></a>
                        </li>
                        <li :class="{ 'is-active': currentTab === 'mt-file-i18n' }">
                            <a @click="changeTab('mt-file-i18n')"><span>{{ $t('Multi actions') }}</span></a>
                        </li>
                    </ul>
                </nav>

                <section class="tab-content card is-card-table is-card-detail">
                    <div class="tab-item">
                        <article :class="{ 'message': true, 'is-primary': successMessage, 'is-danger': errorMessage }"
                                 v-if="errorMessage || successMessage">
                            <div class="message-body">
                                {{ errorMessage }}{{ successMessage }}
                            </div>
                        </article>

                        <template v-if="view('neos')">
                            <div class="columns">
                                <div class="column is-6">
                                    <h4 class="subtitle is-4">{{ $t('Clear cache of Neos web project') }}</h4>
                                    <button @click="executeCommand('default')" class="button is-primary" :disabled="commandProcessing.default">{{ $t('Perform') }}</button>

                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Clear REDIS cache of Neos web project') }}</h4>
                                    <button @click="executeCommand('redis')" class="button is-primary" :disabled="commandProcessing.redis">{{ $t('Perform') }}</button>

                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Clear SQLite cache of Neos web project') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li>{{ $t('Removes all cache files from SQLite cache folder') }}</li>
                                        <li>{{ $t('Do not rebuild caches') }}</li>
                                    </ul>
                                    <button @click="executeCommand('sqlite')" class="button is-primary" :disabled="commandProcessing.sqlite">{{ $t('Perform') }}</button>

                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Rebuild SQLite cache of Neos web project') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li>{{ $t('Removes all cache files from SQLite cache folder') }}</li>
                                        <li>{{ $t('Calls the saved urls after another to rebuild cache') }}</li>
                                        <li>{{ $t('The process can take several hours to be finished') }}</li>
                                    </ul>
                                    <button @click="executeCommand('reSqlite')" class="button is-primary" :disabled="commandProcessing.reSqlite">{{ $t('Perform') }}</button>

                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Start TRADOS import') }}</h4>
                                    <button @click="executeCommand('tradosImport')" class="button is-primary" :disabled="commandProcessing.tradosImport">{{ $t('Start') }}</button>
                                </div>
                                <div class="column is-6" v-if="getPlatform() && getPlatform() !== 'production'">
                                    <h4 class="subtitle is-4">{{ $t('Copy database from live system') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li v-if="neosStatus.start">{{ $t('Started at') }}: {{ neosStatus.start | dateUnix }}</li>
                                        <li v-if="neosStatus.processing">{{ $t('Processing since') }}: {{ neosStatus.processing | dateUnix }}</li>
                                        <li v-if="neosStatus.finished">{{ $t('Last finished at') }}: {{ neosStatus.finished | dateUnix }}</li>
                                    </ul>
                                    <button @click="executeCommand('neos')" class="button is-primary" :disabled="commandProcessing.neos || isAnyStatusActive()">
                                        <span>{{ $t('Perform') }}</span>
                                    </button>
                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Copy media & documents from live system') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li v-if="persistentStatus.start">{{ $t('Started at') }}: {{ persistentStatus.start | dateUnix }}</li>
                                        <li v-if="persistentStatus.processing">{{ $t('Processing since') }}: {{ persistentStatus.processing | dateUnix }}</li>
                                        <li v-if="persistentStatus.finished">{{ $t('Last finished at') }}: {{ persistentStatus.finished | dateUnix }}</li>
                                    </ul>
                                    <button @click="executeCommand('persistent')" class="button is-primary" :disabled="commandProcessing.persistent || isAnyStatusActive()">
                                        <span>{{ $t('Perform') }}</span>
                                    </button>
                                </div>
                            </div>
                        </template>

                        <template v-if="view('mt')">
                            <div class="columns">
                                <div class="column is-6">
                                    <h4 class="subtitle is-4">{{ $t('Import data from SAP') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li>{{ $t('You can import either all or just the selected languages') }}</li>
                                        <li>{{ $t('You can also choose to import either all data or just the updated articles') }}</li>
                                    </ul>
                                    <b-field :addons="false" :label="$t('Import type')">
                                        <div class="control is-clearfix">
                                            <b-radio v-model="importType"
                                                     name="importType"
                                                     native-value="full">
                                                {{ $t('Full import') }}
                                            </b-radio>
                                        </div>
                                        <div class="control is-clearfix">
                                            <b-radio v-model="importType"
                                                     name="importType"
                                                     native-value="update">
                                                {{ $t('Import for updated articles') }}
                                            </b-radio>
                                        </div>
                                    </b-field>
                                    <b-field :addons="false" :label="$t('Languages import')">
                                        <div class="control is-clearfix">
                                            <b-radio v-model="languagesSelection"
                                                     name="languagesSelection"
                                                     native-value="">
                                                {{ $t('All languages') }}
                                            </b-radio>
                                        </div>
                                        <div class="control is-clearfix">
                                            <b-radio v-model="languagesSelection"
                                                     name="languagesSelection"
                                                     native-value="selection">
                                                {{ $t('Language selection') }}
                                            </b-radio>
                                        </div>
                                        <div class="control is-clearfix" v-if="languagesSelection === 'selection'">
                                            <b-checkbox v-model="languages"
                                                        :native-value="availableLanguage.locale"
                                                        v-for="(availableLanguage, key) in availableLanguages"
                                                        :key="key">
                                                {{ availableLanguage.locale }}&nbsp;&nbsp;
                                            </b-checkbox>
                                        </div>
                                    </b-field>
                                    <button @click="importData" class="button is-primary">
                                        <span>{{ $t('Start') }}</span>
                                    </button>

                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Import languages') }}</h4>
                                    <template v-for="(availableLanguage, key) in availableLanguages">
                                        <div v-if="availableLanguage.last_mt_import_started_t > availableLanguage.last_mt_import_finished_t" :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_mt_import_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }})
                                        </div>
                                        <div v-else :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_mt_import_started, availableLanguage.last_mt_import_finished) }})
                                        </div>
                                    </template>
                                    <hr>

                                    <h4 class="subtitle is-4">{{ $t('Export data to SQLite') }}</h4>
                                    <ul class="is-list is-list--disc">
                                        <li>{{ $t('Exports data from MySql to SQLite database') }}</li>
                                        <li>{{ $t('Copies generated file to the Neos web project') }}</li>
                                        <li style="color: rgb(211,0,0);">{{ $t('IMPORTANT: you have to clear SQLite-Cache of neos project after this action') }}</li>
                                    </ul>
                                    <button @click="exportData" class="button is-primary" :disabled="exportLoading">
                                        <span v-if="!exportLoading">{{ $t('Perform') }}</span>
                                        <span v-if="exportLoading">{{ $t('Is running') }}...</span>
                                    </button>
                                </div>
                                <div class="column is-6">
                                    <template v-if="getPlatform() && getPlatform() !== 'production'">
                                        <h4 class="subtitle is-4">{{ $t('Copy media & documents from live system') }}</h4>
                                        <ul class="is-list is-list--disc">
                                            <li v-if="uploadsStatus.start">{{ $t('Started at') }}: {{ uploadsStatus.start | dateUnix }}</li>
                                            <li v-if="uploadsStatus.processing">{{ $t('Processing since') }}: {{ uploadsStatus.processing | dateUnix }}</li>
                                            <li v-if="uploadsStatus.finished">{{ $t('Last finished at') }}: {{ uploadsStatus.finished | dateUnix }}</li>
                                        </ul>
                                        <button @click="executeCommand('uploads')" class="button is-primary" :disabled="commandProcessing.uploads || isAnyStatusActive()">
                                            <span>{{ $t('Perform') }}</span>
                                        </button>
                                        <hr>

                                        <h4 class="subtitle is-4">{{ $t('Copy database from live system') }}</h4>
                                        <ul class="is-list is-list--disc">
                                            <li v-if="dbStatus.start">{{ $t('Started at') }}: {{ dbStatus.start | dateUnix }}</li>
                                            <li v-if="dbStatus.processing">{{ $t('Processing since') }}: {{ dbStatus.processing | dateUnix }}</li>
                                            <li v-if="dbStatus.finished">{{ $t('Last finished at') }}: {{ dbStatus.finished | dateUnix }}</li>
                                        </ul>
                                        <button @click="executeCommand('db')" class="button is-primary" :disabled="commandProcessing.db || isAnyStatusActive()">
                                            <span>{{ $t('Perform') }}</span>
                                        </button>
                                        <hr>

                                        <h4 class="subtitle is-4">{{ $t('Copy SAP-Database from live system') }}</h4>
                                        <ul class="is-list is-list--disc">
                                            <li v-if="sapStatus.start">{{ $t('Started at') }}: {{ sapStatus.start | dateUnix }}</li>
                                            <li v-if="sapStatus.processing">{{ $t('Processing since') }}: {{ sapStatus.processing | dateUnix }}</li>
                                            <li v-if="sapStatus.finished">{{ $t('Last finished at') }}: {{ sapStatus.finished | dateUnix }}</li>
                                        </ul>
                                        <button @click="executeCommand('sap')" class="button is-primary" :disabled="commandProcessing.sap || isAnyStatusActive()">
                                            <span>{{ $t('Perform') }}</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-if="view('mt-file-i18n')">
                            <h4 class="subtitle is-4">{{ $t('Check and create language content for files') }}</h4>
                            <div class="columns">
                                <div class="column is-6">
                                    <bg-form-select
                                        :label="'1. ' + $t('Source: language version')"
                                        name="--random--1"
                                        :addColumnClass="false"
                                        placeholder="Bitte auswählen ..."
                                        :size="6"
                                        aria-required="true"
                                        :options="[...[{ Id: 0, CountryI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'International' }], Languages: [{ Id: 0, CountryId: 0, LocaleCode: 'zz_ZZ', LanguageI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'Ohne Sprache (alt)' }] }] }], ...$root.countries]"
                                        @sync="value => copySourceLanguage = value"
                                        :option-group="option => option.Languages"
                                        :option-group-label="option => $root.translation(option.CountryI18ns, null, 'de_AT').Name"
                                        :option-id="option => option.LocaleCode"
                                        :option-label="option => $root.translation(option.LanguageI18ns, null, 'de_AT').Name + ' (' + option.LocaleCode + ')'"/>

                                    <b-field v-if="folders" :addons="false" :label="'2. ' + $t('Source: folders')">
                                        <div class="control is-clearfix">
                                            <b-checkbox native-value="all"
                                                        :disabled="!!massactionStatus.processing"
                                                        v-model="copyFolderSelection"
                                                        aria-checked="true">
                                                {{ $t('All folders') }}
                                            </b-checkbox>
                                        </div>
                                        <div class="control is-clearfix" style="height: 350px;overflow-y: auto;">
                                            <b-checkbox :native-value="folder.Id"
                                                        v-model="copyFolders"
                                                        :disabled="!!massactionStatus.processing"
                                                        style="width:100%;"
                                                        v-for="(folder, key) in folders"
                                                        :key="key">
                                                {{ $root.translation(folder.FolderI18ns, {}, $root.Storage.get('settings').interfaceLanguage).Name }} | {{ folder.Shortcut }} | {{ folder.CollectionType.PhpObject }}
                                            </b-checkbox>
                                        </div>
                                    </b-field>
                                </div>
                                <div class="column is-6">
                                    <b-field :addons="false" :label="'3. ' + $t('Target: language versions')">
                                        <div class="control is-clearfix">
                                            <b-checkbox native-value="all"
                                                        :disabled="!!massactionStatus.processing"
                                                        v-model="copyLanguagesSelection"
                                                        aria-checked="true">
                                                {{ $t('All languages') }}
                                            </b-checkbox>
                                        </div>
                                        <div class="control is-clearfix">
                                            <b-checkbox :native-value="availableLanguage.locale"
                                                        v-model="copyLanguages"
                                                        :disabled="!!massactionStatus.processing"
                                                        v-for="(availableLanguage, key) in availableLanguages"
                                                        :key="key">
                                                {{ availableLanguage.locale }}&nbsp;&nbsp;
                                            </b-checkbox>
                                        </div>
                                    </b-field>

                                    <div v-if="copyUpdate">
                                        <p>{{ $t('This amount of translation will be created on submit') }}:</p>
                                        <ul>
                                            <li v-for="(item, locale) in copyUpdate" :key="locale">
                                                {{ locale }}: {{ item }}
                                            </li>
                                        </ul>
                                        <br>
                                    </div>

                                    <button @click="performCopyData()"
                                            class="button is-primary"
                                            :disabled="disableCopyButton || !!massactionStatus.processing">
                                        {{ $t('Perform') }}
                                    </button>

                                    <div v-if="copyUpdateCompleted">
                                        <br>
                                        <p>{{ $t('Successfully created') }}:</p>
                                        <ul>
                                            <li v-for="(item, locale) in copyUpdateCompleted" :key="locale">
                                                {{ locale }}: {{ item }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div v-if="massactionStatus.processing">
                                        <br>
                                        <p>{{ $t('Processing since') }}: {{ massactionStatus.processing | dateUnix }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </section>
            </div>

            <template v-if="!isAdmin()">
                {{ $t('You have not enough permissions to see this area') }}
            </template>
        </div>
    </section>
</template>
