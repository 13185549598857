<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
            },
            translation: {
                type: String,
            },
            prefill: {
                type: Array,
                default() {
                    return []
                },
            },
            placeholder: {
                type: String,
                default: 'Tag hinzufügen',
            },
            size: {
                type: Number,
                default: 3,
            },
        },

        data() {
            return {
                value: [],
                tags: [],
                filtered: [],
                prefilled: false,
            }
        },

        watch: {
            value(value) {
                this.$emit('sync', value)
                this.$children[0].updateValue(value)
            },
            options(value) {
                this.optionsIntern = value
            },
            prefill(value) {
                this.value = value
            },
        },

        beforeCreate() {
            if (this.$root.hasPermission('Tag', 111)) {
                this.$root.fetch('tag').then(response => this.tags = response.Tag)
            }
        },

        updated() {
            if (!this.prefilled) {
                this.prefilled = true
                this.value = JSON.parse(JSON.stringify(this.prefill))
            }
            // console.log('updated()', this.prefill)
        },

        methods: {
            typing(text) {
                this.filtered = this.tags.filter(current => current.Name.toLowerCase().includes(text.toLowerCase().trim()))
            },

            newTags() {
                this.$emit('sync', this.value)
            },

            remove() {
                this.newTags()
            },

            add(tag) {
                if (typeof tag === 'string') {
                    if (typeof this.tags.find(current => current.Name.toLowerCase().trim() == tag.toLowerCase().trim()) === 'undefined') {
                        this.$root.post('tag', {TagI18ns: [{Name: tag}]}).then(response => {
                            if ('Tag' in response) {
                                this.tags = [...this.tags, ...response.Tag]
                                this.complete()
                                this.newTags()
                            }
                        })
                    } else {
                        this.complete()
                        this.newTags()
                    }
                }
            },

            complete() {
                this.value.forEach((value, pos) => {
                    if (typeof value === 'string') {
                        let found = this.tags.find(tag => tag.Name.toLowerCase().trim() === value.toLowerCase().trim())
                        if (typeof found !== 'undefined') {
                            this.value[pos] = found
                        }
                    }
                    this.removeDuplicatesFor(this.value[pos].Id)
                })
            },

            removeDuplicatesFor(id) {
                let original = undefined
                let current = 'Kommdirekt GmbH'

                while (current !== -1) {
                    current = this.value.findIndex((current, index) => typeof current === 'object' && current.Id == id && (typeof original === 'undefined' || original !== index))
                    if (typeof original === 'undefined') {
                        original = current
                    } else if (current !== -1) {
                        this.value.splice(current, 1)
                    }
                }
            },
        },
    }
</script>

<template>
    <bg-form-wrapper :label="label" :name="name" :size="size" :prefill="prefill" v-slot="settings">
        <b-taginput
                v-model="value"
                :data="filtered"
                autocomplete
                :allow-new="true"
                icon="label"
                :placeholder="placeholder"
                field="Name"
                type="is-primary"
                :rounded="true"
                :closable="true"
                @typing="typing"
                @remove="remove"
                @add="add">
            <template slot-scope="props">{{ props.option.Name }}</template>
            <template slot="empty">{{ $t('No tags found') }}</template>
        </b-taginput>
    </bg-form-wrapper>
</template>
